.card {
  height: 90%;
  width: 80%;
  box-shadow: -5px 4px 48px 25px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -5px 4px 48px 25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -5px 4px 48px 25px rgba(0, 0, 0, 0.75);
}

.card .home {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas: "dp info";
  grid-template-columns: 1.5fr 2fr;
  align-items: center;
}
.card .info {
  grid-area: info;
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.info .contact-info {
  margin: 25px !important;
  font-family: "Press Start 2P", cursive;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);
}
.contact-info a {
  color: var(--tertiary-color);
  text-decoration: none;
}
.info .proficiency {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proficiency .profile {
  font-size: 1.5em;
  font-style: italic;
}
.proficiency .title {
  margin: 25px;
  font-size: 1.2em;
  font-style: italic;
}

.contact-info > * {
  margin: 7px;
}
.contact-info .name {
  font-size: 3.5rem;
  text-align: center;
}

.home .dp {
  border-radius: 50%;
  box-shadow: 0px 1px 55px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 55px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 55px 5px rgba(0, 0, 0, 0.75);
  width: 100%;
  height: auto;
  object-fit: contain;
}
.home .dp-container {
  grid-area: dp;
  margin: 25px !important;
  max-width: 400px;
  max-height: 400px;
  justify-self: center;
}
.contact-info .name::after {
  content: "";
  margin-left: 10px;
  position: relative;
  display: inline-block;
  background-color: white;
  vertical-align: top;
  width: 10px;
  height: 3.5rem;
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

.skills-logos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.skills-logos img {
  height: 50px;
  width: 50px;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1045px) {
  .home .dp-container {
    max-width: 300px;
    max-height: 300px;
  }
  .card .home {
    grid-template-areas:
      "dp"
      "info";
    justify-items: center;
    grid-template-columns: 1fr;
  }
  .contact-info .name {
    font-size: inherit;
  }

  .contact-info .name::after {
    height: 1em;
  }
  .contact-info a {
    font-size: 0.6em;
  }

  .proficiency .profile {
    font-size: 1.2em;
  }
  .proficiency .title {
    font-size: 1em;
    margin: 15px;
  }

  .skills-logos img {
    height: 40px;
    width: 40px;
  }
}
