@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
:root {
  --primary-color: #171717;
  --secondary-color: white;
  --tertiary-color: #fee715ff;
}

body {
  background-color: var(--primary-color);
  color: white;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  margin: 0px;
  box-sizing: border-box;
}
